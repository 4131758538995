import { checkCaptcha } from 'lib/checkCaptcha';
import './styles/index.scss';

const setVh = () => {
  const vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
};

setVh();
window.addEventListener('resize', setVh);

const scrollTo = (el: HTMLElement | null) => {
  const topOffset: number = el ? el.offsetTop - 64 : 0;

  if ('scrollBehavior' in document.documentElement.style) {
    window.scrollTo({
      top: topOffset,
      behavior: 'smooth'
    });
  } else {
    window.document.documentElement.scrollTop = topOffset;
  }
};

const getCurrentTheme = (): 'dark' | 'light' => {
  let isDark: boolean = window.matchMedia('(prefers-color-scheme: dark)').matches;

  if (localStorage.getItem('theme') === 'dark') {
    isDark = true;
  } else if (localStorage.getItem('theme') === 'light') {
    isDark = false;
  }

  return isDark ? 'dark' : 'light';
};

const theme = getCurrentTheme();
document.documentElement.setAttribute('data-theme', theme);

const toggleSpinner = (element: HTMLElement, param: boolean) => {
  if (element.classList.contains('loading') || !param) {
    element.classList.remove('loading');
    element.querySelector('.spinner')?.remove();
    element.removeAttribute('disabled');
  } else {
    element.classList.add('loading');
    const spinner = document.createElement('div');
    spinner.className = 'spinner';
    element.setAttribute('disabled', 'true');

    for (let i = 0; i < 5; i++) {
      spinner.innerHTML += '<div></div>';
    }

    element.appendChild(spinner);
  }
}

document.addEventListener("DOMContentLoaded", () => {
  document.documentElement.classList.remove('no-js');

  document.getElementById('btn-contact')?.addEventListener('click', () => {
    scrollTo(document.getElementById('form'));
  });

  document.getElementById('form')?.addEventListener('submit', async event => {
    event.preventDefault();

    const $form = event.target as HTMLFormElement;
    const $button = $form.querySelector('button[type="submit"]') as HTMLElement;
    const $formInner = document.getElementById('form-inner') as HTMLElement;
    const $formSuccess = document.getElementById('form-success') as HTMLElement;

    toggleSpinner($button, true);
    $formInner.querySelectorAll('.error').forEach(el => el.remove());

    try {
      const token = await checkCaptcha();
      const formData = new FormData(event.target as HTMLFormElement);
      formData.append('captcha', token);

      const response = await fetch('/contact.php', {
        method: 'POST',
        headers: {
          'Accept': 'application/json'
        },
        body: formData
      });

      if (response.ok) {
        (event.target as HTMLFormElement).reset();
        $formInner.style.display = 'none';
        $formSuccess.style.display = 'block';
      } else {
        const error = await response.json();

        const errorElement = document.createElement('div');
        errorElement.className = 'error';
        errorElement.innerHTML = error.message;

        $formInner.prepend(errorElement);
      }
    } catch (error) {
      const errorElement = document.createElement('div');
      errorElement.className = 'error';
      errorElement.innerHTML = 'An error occurred. Please try again later.';

      $formInner.prepend(errorElement);
    } finally {
      toggleSpinner($button, false);
    }
  });

  // switch theme
  document.getElementById('theme')?.addEventListener('click', () => {
    const updatedTheme = getCurrentTheme() === 'dark' ? 'light' : 'dark';

    localStorage.setItem('theme', updatedTheme);
    document.documentElement.setAttribute('data-theme', updatedTheme);
  });
});


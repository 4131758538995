declare global {
  interface Window {
    grecaptcha: any;
  }
}

const initCaptcha = () => {
  return new Promise<void>(resolve => {
    if (document.getElementById('captcha')) {
      return resolve();
    }

    const container = document.createElement('div');
    container.id = 'captcha';

    document.getElementsByTagName('body')[0].appendChild(container);

    const script = document.createElement('script');
    script.src = 'https://www.google.com/recaptcha/api.js';
    script.async = true;

    document.getElementsByTagName('head')[0].appendChild(script);

    const RECAPTCHA2_SITE_KEY = '6Lcof4kqAAAAACamGxNVvXW5lugeq9Gq3UJdH8NW';

    script.onload = () => {
      window.grecaptcha.ready(() => {
        window.grecaptcha.render('captcha', {
          sitekey: RECAPTCHA2_SITE_KEY,
          size: 'invisible'
        });

        resolve();
      });
    };
  });
};

export const checkCaptcha = async () => {
  await initCaptcha();

  return new Promise<string>(async resolve => {
    window.grecaptcha.reset();
    await window.grecaptcha.execute();

    const checker = setInterval(() => {
      const token: string = window.grecaptcha.getResponse();

      if (token) {
        resolve(token);
        clearInterval(checker);
      }
    }, 50);
  });
};
